<template>
  <div>
    <slot name="header">
      <h3>Manage Your Vehicles</h3>
      <p>
        You can add, edit, or remove your vehicles here.
      </p>
    </slot>
    <ApiError class="mt-4" :errors="errors"></ApiError>
    <AlertDisplay ref="alertRef" durationOption="long" />
    <v-card shaped>
      <v-data-table
        :items="vehicles.existing"
        :headers="headers"
        no-data-text="Add at least 1 vehicle before continuing"
        disable-sort
        disable-filtering
        disable-pagination
        hide-default-footer
      >
        <template v-slot:top>
          <v-card shaped dark>
            <v-container>
              <v-row>
                <v-col cols="12" class="d-flex">
                  <v-text-field
                    label="Enter Valid VIN"
                    class="required"
                    v-model="form.vin"
                    dense
                    outlined
                    hide-details
                  ></v-text-field>
                  <div class="btn-container ml-2">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          variant="outlined"
                          class="secondary-btn"
                          :disabled="isLoading || !form.vin"
                          @click="addVehicle"
                          :loading="isLoading"
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </template>
                      Add Vehicle
                    </v-tooltip>
                    <template v-if="transferHistory.length > 0">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" variant="outlined" class="history-btn" @click="openHistoryDialog">
                            <v-icon>mdi-history</v-icon>
                          </v-btn>
                        </template>
                        View Transfer History
                      </v-tooltip>
                    </template>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          <v-dialog v-model="historyDialog" max-width="800px" scrollable>
            <v-card>
              <v-card-title>
                <span class="text-h6">Vehicle Transfer History</span>
              </v-card-title>
              <v-card-text>
                <v-data-table
                  :items="transferHistory"
                  :headers="historyHeaders"
                  :server-items-length="transferHistoryTotal"
                  :options.sync="historyOptions"
                  :loading="historyLoading"
                  loading-text="Loading transfer history..."
                  class="elevation-1"
                >
                  <template v-slot:item.createdDate="{ item }">
                    {{ item.createdDate | formatDateClient("MM/DD/YYYY hh:mm a", selectedClient) }}
                  </template>
                  <template v-slot:item.productRegistration.productRegistrationItems.serialNumber="{ item }">
                    {{ item.productRegistration?.productRegistrationItems?.[0]?.serialNumber ?? "N/A" }}
                  </template>
                  <template v-slot:item.transferredTo.user.firstName="{ item }">
                    {{ item.transferredTo?.fullName || "N/A" }}
                  </template>
                </v-data-table>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="historyDialog = false" color="primary" text>Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="error" class="mr-2 ml-2" v-bind="attrs" v-on="on" @click="removeVehicle(item)">
                mdi-delete
              </v-icon>
            </template>
            <span>Remove Vehicle</span>
          </v-tooltip>
        </template>
        <template v-slot:item.productKey="{ item }">
          {{ item.product.productKey || "N/A" }}
        </template>
        <template v-slot:item.name="{ item }">
          {{ item.product.name || "N/A" }}
        </template>
        <template v-slot:item.year="{ item }">
          {{ item.product.customFieldValue2 || "N/A" }}
        </template>
      </v-data-table>
    </v-card>
    <ConfirmDialog
      ref="confirmDialog"
      :title="'Confirm Deletion'"
      :text="'Are you sure you want to delete this Vehicle?'"
      :confirm-text="'Delete'"
      :cancel-text="'Cancel'"
      :is-loading="isLoading"
      @confirm="handleDelete"
      :privilege="{ delete: deletePrivilege }"
    />

    <v-dialog v-model="transfer.dialog" max-width="500px">
      <v-card>
        <v-card-title class="headline">
          VIN Already Registered
        </v-card-title>

        <v-card-text>{{ transfer.message }}</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="initiateTransfer()" :loading="this.transfer.isLoading"
            >Yes, Start Transfer</v-btn
          >
          <v-btn @click="closeTransferDialog()">No, Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import AlertDisplay from "@/gapp-components/components/display/AlertDisplay.vue";
import ApiError from "@/gapp-components/components/display/ApiError.vue";
import { mapGetters } from "vuex/dist/vuex.common.js";
import ConfirmDialog from "../../../gapp-components/components/dialogs/ConfirmDialog.vue";
export default {
  components: { ApiError, ConfirmDialog, AlertDisplay },
  name: "VinTableManager",
  props: {
    value: {
      type: Object,
      default: () => ({ existing: [], delete: [], isValid: false, refreshRequired: false })
    },
    loading: Boolean,
    public: Boolean
  },
  data() {
    return {
      headers: [
        { value: "serialNumber", text: "VIN" },
        { value: "productKey", text: "Model #" },
        { value: "name", text: "Model Name" },
        { value: "year", text: "Year" },
        { value: "actions", text: "" }
      ],
      vehicles: this.value,
      isLoading: this.loading,
      deleteVehicle: null,
      errors: {},
      form: {
        vin: ""
      },
      transfer: {
        dialog: false,
        message: null,
        vinInfo: null,
        isLoading: false
      },
      historyDialog: false,
      transferHistory: [],
      transferHistoryTotal: 0,
      historyLoading: false,
      historyHeaders: [
        { text: "Transfer Date", value: "createdDate" },
        { text: "VIN", value: "productRegistration.productRegistrationItems.serialNumber" },
        { text: "Transferred To", value: "transferredTo.user.firstName" }
      ],
      historyOptions: {
        itemsPerPage: 10
      }
    };
  },
  watch: {
    value(newVal) {
      this.vehicles = newVal;
    },
    vehicles: {
      deep: true,
      handler(newVal) {
        this.$emit("update:value", newVal);
      }
    },
    historyOptions: {
      handler() {
        this.fetchTransferHistory();
      }
    }
  },
  methods: {
    initiateTransfer() {
      this.transfer.isLoading = true;

      const tranferForm = {
        productRegistration: {
          id: this.transfer.vinInfo.id
        },
        transferredFrom: {
          id: this.transfer.vinInfo.participant.id
        },
        transferredTo: {
          id: this.selectedParticipant.id
        },
        emailTemplateParticipantForm: {
          emailTemplateKey: "VIN_TRANSFER_PENDING",
          context: {
            serialNumber: this.form.vin
          }
        }
      };
      this.$api
        .post("/api/productRegistrationTransfers/create", tranferForm)
        .then(() => {
          this.transfer.message = null;
          this.transfer.productRegistration = null;
          this.vehicles.isValid = true;

          // Display success message
          let racerOwner = this.transfer.vinInfo.participant.fullName;
          let vinNumber = this.transfer.vinInfo?.productRegistrationItems[0]?.serialNumber;
          this.showSuccessMessage(racerOwner, vinNumber);
        })
        .finally(() => {
          this.transfer.dialog = false;
          this.transfer.isLoading = false;
        });
    },
    showSuccessMessage(racerOwner, vinNumber) {
      if (!racerOwner || !vinNumber) return;

      this.$refs?.alertRef?.success(
        `An email was sent to ${racerOwner} notifying that you have started a transfer for VIN "${vinNumber}".`
      );

      setTimeout(() => {
        this.$refs?.alertRef?.resetAlert();
      }, 3000);
    },
    closeTransferDialog() {
      this.transfer.dialog = false;
      this.transfer.message = null;
      this.transfer.productRegistration = null;
    },

    async addVehicle() {
      this.isLoading = true;
      this.errors = {};

      if (this.existVehicle({ serialNumber: this.form.vin })) {
        this.isLoading = false;
        this.form.vin = "";
        return;
      }

      const vehicle = await this.findVehicleByVIN(this.form.vin);

      if (!vehicle) {
        this.errors = { message: "Vehicle not found for VIN: " + this.form.vin };
        this.isLoading = false;

        return;
      }

      this.transfer.vinInfo = await this.isVinInUseByAnotherRacer(this.form.vin);
      if (this.transfer.vinInfo) {
        const hasTransferStarted = await this.hasTransferStarted(this.form.vin);

        if (hasTransferStarted) {
          this.errors = { message: `You have already initiated a transfer for the VIN ${this.form.vin}` };
        } else {
          this.transfer.message = `The VIN ${this.form.vin} is already registered to another racer (Owner: ${this.transfer.vinInfo.participant.fullName}). Would you like to initiate a transfer request for this VIN?`;
          this.transfer.dialog = true;
        }

        this.isLoading = false;

        return;
      }

      const newVehicle = { serialNumber: this.form.vin, product: vehicle, dirty: true };
      await this.saveVehicle(newVehicle);
      this.vehicles.existing.push(newVehicle);

      this.form.vin = "";
      this.errors = {};
      this.isLoading = false;
    },
    async saveVehicle(vehicle) {
      let productRegistrationForm = {
        productRegistrationItems: [
          {
            product: vehicle.product,
            serialNumber: vehicle.serialNumber
          }
        ]
      };

      try {
        let response = await this.$api.post("/api/productRegistrations/submit", productRegistrationForm);
        if (response && response.data) {
          const registeredVehicle = response.data;
          vehicle.registrationId = registeredVehicle.id;
          vehicle.dirty = false;
        }
      } catch (error) {
        this.errors = this.$api.getErrorsFromResponse(error);
        throw error;
      }
    },
    async deleteVehicleById(registrationId) {
      await this.$api.delete(`/api/productRegistrations/${registrationId}/delete`);
    },
    existVehicle(vehicle) {
      const exists = this.vehicles.existing.some(v => v.serialNumber === vehicle.serialNumber);
      if (exists) {
        this.errors = { message: "This vehicle was already added." };
        return true;
      }
      return false;
    },

    async findVehicleByVIN(vin) {
      try {
        const { data } = await this.$api.get(`/api/productSerialNumbers/bySerialNumber/${vin}`);
        return data;
      } catch (error) {
        if (error.response?.data?.status === 404) {
          this.errors = { message: "Vehicle not found for VIN: " + vin };
        } else {
          this.errors = this.$api.getErrorsFromResponse(error);
        }
        return null;
      }
    },

    async isVinInUseByAnotherRacer(vin) {
      let searchForm = { serialNumber: vin };
      try {
        const { data } = await this.$api.post("/api/productRegistrations/search", searchForm);
        if (data.content && data.content.length > 0) {
          return data.content[0];
        }
        return null;
      } catch (error) {
        console.error("Error checking VIN:", error);
        return null;
      }
    },

    async hasTransferStarted(vin) {
      let searchForm = {};
      searchForm.serialNumber = vin;
      searchForm.transferredTo = { id: this.selectedParticipant.id };
      searchForm.status = { name: "PENDING" };
      try {
        const { data } = await this.$api.post("/api/productRegistrationTransfers/search", searchForm);
        if (data.content && data.content.length > 0) {
          return data.content[0];
        }

        return null;
      } catch (error) {
        console.error("Error checking Transfer Status:", error);
        return null;
      }
    },

    async fetchVehicleInfo(vin) {
      let url = `/api/productSerialNumbers/bySerialNumber/${vin}`;
      let { data } = await this.$api.get(url);
      return data;
    },

    removeVehicle(vehicle) {
      this.deleteVehicle = vehicle;
      this.$refs.confirmDialog.open();
    },
    async handleDelete() {
      this.isLoading = true;
      try {
        const index = this.vehicles.existing.indexOf(this.deleteVehicle);
        if (index !== -1) {
          if (this.deleteVehicle && this.deleteVehicle.registrationId) {
            await this.deleteVehicleById(this.deleteVehicle.registrationId);
          }
          this.vehicles.refreshRequired = true;
          this.vehicles.existing.splice(index, 1);
        }
      } catch (error) {
        this.errors = this.$api.getErrorsFromResponse(error);
      } finally {
        this.deleteVehicle = null;
        this.isLoading = false;
        this.$refs.confirmDialog.close();
      }
    },

    handleTransfer() {},

    async openHistoryDialog() {
      this.historyDialog = true;
    },

    async fetchTransferHistory() {
      this.historyLoading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.historyOptions;

      const searchForm = {
        transferredFrom: { id: this.selectedParticipant.id }
      };

      try {
        const { data } = await this.$api.post(
          "/api/productRegistrationTransfers/search?size=" +
            itemsPerPage +
            "&page=" +
            (page - 1) +
            (sortBy && sortBy.length > 0
              ? "&sort=" + sortBy[0] + "," + ((sortDesc && sortDesc.length > 0) & sortDesc[0] ? "DESC" : "ASC")
              : ""),
          searchForm
        );
        this.transferHistory = data.content || [];
        this.transferHistoryTotal = data.totalElements;
      } catch (error) {
        console.error("Error fetching transfer history", error);
        this.errors = this.$api.getErrorsFromResponse(error);
        this.historyDialog = false;
      } finally {
        this.historyLoading = false;
      }
    },

    async fetchByRegisteredVehicles() {
      this.vehicles.delete = [];
      let url = "/api/productRegistrations/search";
      let { data } = await this.$api.post(url, {
        participant: { id: this.selectedParticipant.id }
      });
      this.vehicles.existing = data.content.reduce((acc, item) => {
        const mappedVehicles = item.productRegistrationItems.map(({ id, product, serialNumber }) => ({
          product,
          serialNumber,
          dirty: false,
          id,
          registrationId: item.id
        }));
        return acc.concat(mappedVehicles);
      }, []);
    },
    deletePrivilege() {
      return this.$privilege.hasPrivilege("PRODUCT_REGISTRATION_DELETE");
    }
  },
  mounted() {
    this.fetchByRegisteredVehicles();
    this.fetchTransferHistory();
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedClient"])
  }
};
</script>

<style scoped>
.btn-container {
  display: flex;
  justify-content: flex-end;
  column-gap: 8px;
}
.secondary-btn {
  border: 2px solid #fff !important;
  color: #fff !important;
}

.history-btn {
  border: 2px solid #69be28 !important;
  color: #69be28 !important;
}
</style>
