<template>
  <v-dialog v-model="showDialog" persistent max-width="600px">
    <v-card>
      <v-card-title class="text-h5">{{ $i18n.translate(title) }}</v-card-title>
      <v-card-text>{{ $i18n.translate(text) }}</v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="cancel" :disabled="isLoading">
          {{ $i18n.translate(cancelText) }}
        </v-btn>
        <v-btn v-if="privilege && privilege.delete" color="red darken-1" text :disabled="isLoading" @click="confirm">
          <v-progress-circular indeterminate size="20" v-if="isLoading" />
          <span v-else>{{ $i18n.translate(confirmText) }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmDialog",
  props: {
    title: {
      type: String,
      default: "Confirm"
    },
    text: {
      type: String,
      default: "Are you sure you want to proceed?"
    },
    confirmText: {
      type: String,
      default: "Confirm"
    },
    cancelText: {
      type: String,
      default: "Cancel"
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    privilege: {
      type: Object,
      default: () => ({
        delete: false
      })
    }
  },
  data() {
    return {
      showDialog: false
    };
  },
  methods: {
    confirm() {
      this.$emit("confirm");
      this.dialog = false;
    },
    cancel() {
      this.$emit("cancel");
      this.showDialog = false;
    },
    open() {
      this.showDialog = true;
    },
    close() {
      this.showDialog = false;
    }
  }
};
</script>
