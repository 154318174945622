<template>
  <v-card flat v-bind="$attrs" v-on="$listeners" :loading="loading">
    <div class="text-center" v-if="loading">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <v-form ref="participantForm" @submit.prevent="onSubmit" v-if="!loading" v-model="validForm" @input="input">
      <v-container>
        <v-row>
          <v-col class="pb-0">
            <v-alert border="top" type="info">
              Please use the form below to update your racing information.
            </v-alert>
            <v-alert dismissible border="top" type="success" v-if="profileUpdated">
              Racing Information Updated Successfully
            </v-alert>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="8" xs="12" sm="12" md="8" lg="8" xl="8">
            <h3 class="mb-5">Please enter your rider status and type</h3>
            <p>For "Rider Type" you may select more than one option.</p>

            <CustomField
              dense
              v-if="selectedProgram?.participantCustomFields"
              v-model="participantForm.customFieldValue7"
              :customField="selectedProgram.participantCustomFields[6]"
              class="required"
              outlined
            />

            <CustomField
              dense
              v-if="selectedProgram?.participantCustomFields"
              v-model="participantForm.customFieldValue11"
              :customField="selectedProgram.participantCustomFields[10]"
              class="required"
              outlined
            />

            <h3 class="mt-4">
              Were you referred?
            </h3>
            <CustomField
              :label="
                $i18n.translate('Were you referred to this program by an authorized Kawasaki motorcycle dealership?')
              "
              dense
              v-if="selectedProgram?.participantCustomFields"
              v-model="participantForm.customFieldValue10"
              :customField="selectedProgram.participantCustomFields[9]"
              class="required"
              outlined
            />

            <h3 class="mt-10 mb-5">Please enter optional rider details</h3>
            <p>
              If you are entering responses for "Classes" and "Series", please separate your responses with a comma.
            </p>

            <CustomField
              dense
              v-if="selectedProgram?.participantCustomFields"
              v-model="participantForm.customFieldValue12"
              :customField="selectedProgram.participantCustomFields[11]"
              outlined
            />
            <CustomField
              dense
              v-if="selectedProgram?.participantCustomFields"
              v-model="participantForm.customFieldValue13"
              :customField="selectedProgram.participantCustomFields[12]"
              outlined
            />
            <CustomField
              dense
              v-if="selectedProgram?.participantCustomFields"
              v-model="participantForm.customFieldValue14"
              :customField="selectedProgram.participantCustomFields[13]"
              outlined
            />
            <CustomField
              dense
              v-if="selectedProgram?.participantCustomFields"
              v-model="participantForm.customFieldValue15"
              :customField="selectedProgram.participantCustomFields[14]"
              outlined
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn type="submit" color="primary" :disabled="!validForm">
              Update
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-card>
</template>

<script>
import CustomField from "@/gapp-components/components/fields/CustomField.vue";
import ApiService from "@/gapp-components/services/api.service";
import { mapGetters } from "vuex";

export default {
  name: "ProfileRacerTab",
  components: {
    CustomField
  },
  data() {
    return {
      validForm: false,
      loading: true,
      participantForm: {},
      errors: {},
      profileUpdated: false
    };
  },
  props: {
    value: [Boolean, Event]
  },
  methods: {
    fetchData() {
      this.loading = true;
      this.errors = {};

      ApiService.get(`/api/participants/${this.selectedParticipant.id}`)
        .then(({ data }) => {
          this.participantForm = data;
        })
        .catch(error => {
          this.errors = ApiService.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
          this.$emit("onLoad");
        });
    },
    input() {
      this.$emit("input", this.validForm);
    },
    onSubmit() {
      this.loading = true;
      this.errors = {};

      let form = {
        customFieldValue7: this.participantForm.customFieldValue7, // Rider status
        customFieldValue11: this.participantForm.customFieldValue11.join("|"), // Rider type
        customFieldValue10: this.participantForm.customFieldValue10, // Referred?
        customFieldValue12: this.participantForm.customFieldValue12, // AMA License Number
        customFieldValue13: this.participantForm.customFieldValue13, // Riding Number
        customFieldValue14: this.participantForm.customFieldValue14, // Classes
        customFieldValue15: this.participantForm.customFieldValue15 // Series
      };

      Promise.all([this.$api.patch(`/api/participants/${this.selectedParticipant.id}`, form)])
        .then(() => this.$api.get("/api/participants/bySelectedParticipant"))
        .then(({ data }) => this.$auth.storeSelectParticipant(data))
        .then(() => {
          window.scrollTo(0, 0);
          this.profileUpdated = true;
          setTimeout(() => {
            this.profileUpdated = false;
          }, 4000);
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
          this.fetchData();
        });
    }
  },
  mounted() {
    this.validForm = this.value;
    this.fetchData();
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedProgram"])
  }
};
</script>
