<template>
  <v-card>
    <v-toolbar flat>
      <v-toolbar-title>VIN Transfer Management</v-toolbar-title>
      <v-spacer />
      <span class="mt-8">
        <v-select
          dense
          outlined
          v-model="statusValue"
          :label="$i18n.translate('Status')"
          :items="filterByStatus"
          item-value="name"
          item-text="description"
          @change="onChangeStatus"
        ></v-select>
      </span>
    </v-toolbar>
    <v-card-text>
      <ApiError :errors="errors" />
      <v-data-table
        :headers="headers"
        :items="items"
        class="elevation-1"
        item-key="id"
        :loading="loading"
        :options.sync="options"
        :server-items-length="total"
        :footer-props="{ 'items-per-page-options': [10, 25, 50] }"
        v-if="this.items"
      >
        <template v-slot:item.productRegistration.productRegistrationItems.serialNumber="{ item }">
          {{ item.productRegistration.productRegistrationItems[0].serialNumber }}
        </template>
        <template v-slot:item.transferredTo.user.firstName="{ item }">
          {{ item.transferredTo?.fullName || "N/A" }}
        </template>
        <template v-slot:item.transferredFrom.user.firstName="{ item }">
          {{ item.transferredFrom?.fullName || "N/A" }}
        </template>

        <template v-slot:item.createdDate="{ item }">
          {{ item.createdDate | formatDateClient("MM/DD/YYYY hh:mm a z", selectedClient) }}<br />
          ({{ item.createdDate | formatDateFromNow }})
        </template>

        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: onTooltip }">
              <v-btn
                v-on="{ ...onTooltip }"
                class="ma-2"
                @click="onApprove(item)"
                :disabled="!disablebtn"
                icon
                color="blue lighten-2"
              >
                <v-icon>mdi-thumb-up</v-icon>
              </v-btn>
            </template>
            <span>{{ $i18n.translate("Approve") }}</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on: onTooltip }">
              <v-btn
                v-on="{ ...onTooltip }"
                class="ma-2"
                @click="onReject(item)"
                :disabled="!disablebtn"
                icon
                color="red lighten-2"
              >
                <v-icon>mdi-thumb-down</v-icon>
              </v-btn>
            </template>
            <span>{{ $i18n.translate("Reject") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-dialog v-model="approveDialog" max-width="600px" persistent>
        <v-card>
          <v-card-title class="headline">Approve VIN Transfer?</v-card-title>
          <v-card-text>
            <p>
              The request for VIN transfer to
              <b>{{ itemSelected?.transferredTo?.fullName }} </b> will be Approved.
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="close">{{ $i18n.translate("Cancel") }}</v-btn>
            <v-btn color="primary" @click="approve" :loading="loading">Approve</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="rejectDialog" max-width="600px">
        <v-form @submit.prevent="reject" v-model="rejectReasonValid">
          <v-card>
            <v-card-title>
              <span class="headline"> Reject VIN Transfer?</span>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <p>
                    The request for VIN transfer to
                    <b>{{ itemSelected?.transferredTo?.fullName }} </b> will be Rejected.
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    label="Comments"
                    name="reason"
                    id="reason"
                    v-model="rejectReason"
                    :rules="rules.reason"
                    :error-messages="$error.getValidationError(errors, 'reason')"
                    @input="$error.clearValidationError(errors, 'reason')"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="close()">{{ $i18n.translate("Cancel") }}</v-btn>
              <v-btn color="primary" @click="reject" :loading="loading" :disabled="!rejectReasonValid">Reject</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import ApiError from "@/gapp-components/components/display/ApiError.vue";
import { mapGetters } from "vuex";
import ValidationService from "@/gapp-components/services/validation.service";

export default {
  name: "vinTransferManagement",
  components: { ApiError },
  data() {
    return {
      loading: false,
      items: [],
      total: 0,
      errors: {},
      options: {
        itemsPerPage: 10
      },
      headers: [
        { text: "VIN", value: "productRegistration.productRegistrationItems.serialNumber" },
        { text: "Transfer To", value: "transferredTo.user.firstName" },
        { text: "Transfer From", value: "transferredFrom.user.firstName" },
        { text: "Status", value: "status.name", sortable: false },
        { text: "Date", value: "createdDate" },
        { text: "Actions", value: "actions", sortable: false }
      ],
      itemSelected: {},
      approveDialog: false,
      rejectDialog: false,
      rejectReason: undefined,
      rejectReasonValid: true,
      showRejectReason: false,
      disablebtn: true,
      rules: {
        reason: ValidationService.generateTextValidationRules("Reason", 255)
      },
      statusValue: "PENDING",
      filterByStatus: [
        { name: "PENDING", description: "Pending" },
        { name: "ACCEPTED", description: "Accepted" },
        { name: "REJECTED", description: "Rejected" }
      ]
    };
  },
  methods: {
    onApprove(item) {
      this.approveDialog = true;
      this.itemSelected = item;
    },
    approve() {
      this.loading = true;
      let approvedForm = {
        emailTemplateParticipantForm: {
          emailTemplateKey: "VIN_TRANSFER_APPROVED",
          context: {
            serialNumber: this.itemSelected.productRegistration?.productRegistrationItems[0]?.serialNumber
          }
        }
      };

      this.$api
        .patch("/api/productRegistrationTransfers/approve/" + this.itemSelected.id, approvedForm)
        .then(() => {
          this.close();
          this.getData();
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onReject(item) {
      this.rejectDialog = true;
      this.itemSelected = item;
    },
    reject() {
      this.loading = true;
      let rejectedForm = {
        reason: this.rejectReason,
        emailTemplateParticipantForm: {
          emailTemplateKey: "VIN_TRANSFER_REJECTED",
          context: {
            serialNumber: this.itemSelected.productRegistration?.productRegistrationItems[0]?.serialNumber,
            reason: this.rejectReason
          }
        }
      };
      this.$api
        .patch("/api/productRegistrationTransfers/reject/" + this.itemSelected.id, rejectedForm)
        .then(() => {
          this.close();
          this.getData();
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.approveDialog = false;
      this.rejectDialog = false;
      this.itemSelected = {};
    },
    onChangeStatus() {
      this.showRejectReason = this.statusValue === "REJECTED";
      this.disablebtn = this.statusValue === "PENDING";

      if (this.showRejectReason && !this.headers.find(h => h.value === "rejectedReason")) {
        this.headers.splice(3, 0, { text: "Reject Reason", value: "rejectedReason" });
      } else if (!this.showRejectReason) {
        this.headers = this.headers.filter(h => h.value !== "rejectedReason");
      }
      this.getData();
    },
    getData() {
      this.loading = true;

      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      let filters = {};
      if (this.selectedParticipant?.participantType?.participantTypeKey !== "100") {
        filters.transferredFrom = { id: this.selectedParticipant.id };
      }
      filters.status = { name: this.statusValue };

      this.$api
        .post(
          "/api/productRegistrationTransfers/search?size=" +
            itemsPerPage +
            "&page=" +
            (page - 1) +
            (sortBy && sortBy.length > 0
              ? "&sort=" + sortBy[0] + "," + ((sortDesc && sortDesc.length > 0) & sortDesc[0] ? "DESC" : "ASC")
              : ""),
          filters
        )
        .then(({ data }) => {
          this.loading = false;
          this.items = data.content;
          if (this.statusValue === "PENDING") {
            this.$emit("update-has-data", this.items.length > 0);
          } else {
            this.$emit("update-has-data", false);
          }
          this.total = data.totalElements;
        });
    }
  },
  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(["selectedClient", "selectedParticipant"])
  }
};
</script>
